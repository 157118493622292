html {
  font-size: min(15px + .4vw, 24px);
}

body {
  text-align: center;
  max-width: initial;
  margin: 0 auto;
  padding: 0;
  line-height: 2em;
  overflow-x: hidden;
}

.talebox-meta {
  fill: var(--background-alt);
  width: 120vw;
  height: 20vw;
  display: block;
  position: relative;
  left: -10vw;
}

hr.section {
  height: 50vh;
  border-top: 0;
  border-left: 1px solid #8885;
}

.sections {
  max-width: 800px;
  flex-flow: column;
  gap: 2em;
  margin: auto;
  padding-block: 30vh;
  display: flex;
}

.when-visible.fade {
  opacity: 0;
  transition: all 2s cubic-bezier(0, .9, .9, 1);
  transform: translateY(20px)scale(.94);
}

.when-visible.fade.visible {
  opacity: 1;
  transform: translateY(0)scale(1);
}

@keyframes move {
  100% {
    offset-distance: 100%;
  }
}

.hovering {
  transition: all 3s;
}

.up, .down {
  transform: none;
}

.visible .up {
  transform: translateY(-20px);
}

.visible .down {
  transform: translateY(20px);
}

hr.section {
  height: 30vh;
}

/*# sourceMappingURL=index.99bffa06.css.map */
