html {
	font-size: min(calc(15px + 0.4vw), 24px);
}

body {
	text-align: center;
	line-height: 2em;
	padding: 0;
	margin: 0 auto 0 auto;
	max-width: initial;
	overflow-x: hidden;
}

.talebox-meta {
	display: block;
	fill: var(--background-alt);
	width: 120vw;
	position: relative;
	left: -10vw;
	height: 20vw;
}


hr.section {
	border-top: 0;
	border-left: 1px solid #8885;
	height: 50vh;
}


.sections {
	display: flex;
	flex-flow: column;
	gap: 2em;
	padding-block: 30vh;
	max-width: 800px;
	margin: auto;
}

.when-visible.fade {
	transition: all 2s;
	transition-timing-function: cubic-bezier(0, .9, .9, 1);
	opacity: 0;
	transform: translateY(20px) scale(94%);
}

.when-visible.fade.visible {
	opacity: 1;
	transform: translateY(0px) scale(100%);
}

@keyframes move {
	100% {
		offset-distance: 100%;
	}
}

.hovering {
	transition: all 3s;
	/* animation: move 5s infinite linear; */
	/* offset: path("M0 0a2 2 0 0 0 4 -4a2 2 0 0 0 -4 4a2 2 0 0 1 -4 4a2 2 0 0 1 4 -4z") 0deg / 0 0; */
}

.up,
.down {
	transform: none;
}

.visible .up {
	transform: translateY(-20px);
}

.visible .down {
	transform: translateY(20px);
}

hr.section {
	height: 30vh
}

